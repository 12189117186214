// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------


/// Regular font family
/// @type List
$text-font-stack: 'Verlag A', 'Verlag B', aktiv-grotesk, sans-serif !default;

/// Code (monospace) font family
/// @type List
$code-font-stack: 'Courier New', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Monaco', monospace !default;

/// @type Color
$black: rgb(0,0,0) !default;
$text-color: rgb(34, 34, 34) !default;
/// @type Color
$white: #fff !default;

/// @type Color
$text-primary: #092c7e !default;
/// @type Color
$text-breadcrumbs: #2c9dc5 !default;

/// Light grey
/// @type Color
$light-grey: #a9a9a9 !default;

/// Medium grey
/// @type Color
$mid-grey: #777 !default;

/// Dark grey
/// @type Color
$dark-grey: rgb(68, 68, 68) !default;


/// @type Color
$question-wrapper-bg: rgb(216,226,240) !default;
/// @type Color
$button-blue: rgb(140, 191, 250) !default;
/// @type Color
$button-green: rgb(81, 210, 194) !default;
/// @type Color
$button-purple: rgb(214, 102, 205) !default;
/// @type Color
$button-orange: rgb(252, 171, 82) !default;
/// @type Color
$button-lime: rgb(126, 211, 33) !default;
/// @type Color
$button-yellow: rgb(248, 232, 27) !default;

/// ButtonColor Collection:
/// @type Color
$light-blue: #64c3e4 !default;
/// @type Color
$medium-blue: #2670c5 !default;
/// @type Color
$dark-blue: #092c7e !default;
/// @type Color
$red: #f80000 !default;
/// @type Color
$hsw-dark: rgb(2,62,100) !default;
/// @type Color
$hsw-light: rgb(1,125,184) !default;


/// Container's maximum width
/// @type Length
$max-width: 1180px !default;


/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  'small': (max-width: 600px),
  'medium': (max-width: 1000px),
  'large': (min-width: 1201px),
) !default;




/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '/assets/' !default;