// Card Animation
.card-animation__container {
    width: 100%;
    max-width: 1000px;
    min-height: 150px;
    display: inline-flex;
    position: absolute;
    top: -60px;
    @include respond-to('small') {
        overflow: hidden;
        top: -50px;
    }
}

.animation-item__container {
    max-width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: visible;
    will-change: transform;
}

.animation-icon {
    font-size: 250px;
    transform: rotate(-25deg);
    padding: 40px;
    @include respond-to('small') {
        padding: 20px;
    }
}

.green-card {
    color: rgb(1, 158, 71)
}
.blue-card {
    color: rgb(20, 107, 180);
}

svg:not(:root).svg-inline--fa {
    overflow: auto;
}

@keyframes dip {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(7vh);
    }
}
@keyframes dip-mobile {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(30px);
    }
}


.card-animation-item__0 {
    animation-delay: 0s;
    animation-name: dip;
    animation-duration: 2.5s;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    animation-iteration-count: 1;
    animation-fill-mode: none;
    animation-play-state: running;
    @include respond-to('small') {
        animation-name: dip-mobile;
    }
}

.card-animation-item__1 {
    animation-delay: .5s;
    animation-name: dip;
    animation-duration: 2.5s;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    animation-iteration-count: 1;
    animation-fill-mode: none;
    animation-play-state: running;
    @include respond-to('small') {
        animation-name: dip-mobile;
    }
}

.card-animation-item__2 {
    animation-delay: 1s;
    animation-name: dip;
    animation-duration: 2.5s;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    animation-iteration-count: 1;
    animation-fill-mode: none;
    animation-play-state: running;
    @include respond-to('small') {
        animation-name: dip-mobile;
    }
}

.card-animation-item__3 {
    animation-delay: 1.5s;
    animation-name: dip;
    animation-duration: 2.5s;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    animation-iteration-count: 1;
    animation-fill-mode: none;
    animation-play-state: running;
    @include respond-to('small') {
        animation-name: dip-mobile;
    }
}
